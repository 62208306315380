import { useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { type ReactNode, useEffect, useMemo, useState } from "react";
import type { ApiResponse } from "../../api";
import api from "../../api";
import { useAnlageStromstg } from "../../hooks/useAnlageStromstg";
import urls from "../../urls";
import type { Generator } from "../../utils/backend-types";
import { CREATE_STEPS } from "../../utils/constants";
import { ObjectName, Product } from "../../utils/enums";
import { getPluralVariableNameFromObjectName } from "../../utils/getPluralVariableNameFromObjectName";
import { showToast } from "../../utils/toast";
import { GENERATOR_TYPES } from "../ComponentListContainer/ComponentList/ComponentEditWizard/Data/Generator";
import { getFormFieldsFromResponse } from "../CustomForm/CustomForm";
import { DeliveryConstraintTitle } from "../DeliveryConstraintsModal/DeliveryConstraintsModal";
import { DeliveryConstraintsWizardStep } from "../DeliveryConstraintsWizardStep/DeliveryConstraintsWizardStep";
import { DynamicFormWithLoader } from "../DynamicForm/DynamicForm";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import { AnimatedLoadingIcon } from "../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import type { FieldsArray, FieldsDict } from "../OptionsForm/OptionsForm";
import { GeneratorBatchImportSuccessWidget } from "../VariantObjectWizard/ImportOrCreateStep/GeneratorBatchImportSuccessWidget/GeneratorBatchImportSuccessWidget";
import {
  GeneratorMaStRImportSuccessWidget,
  ImportOrCreateStep
} from "../VariantObjectWizard/ImportOrCreateStep/ImportOrCreateStep";
import { AnlageStromstgFlow } from "./AnlageStromstgFlow/AnlageStromstgFlow";
import "./GeneratorWizard.scss";
import { CreateFormStep } from "./Steps/CreateFormStep";
import { FinancialDataStep } from "./Steps/FinancialDataStep";
import { LoadprofileSelectionStep } from "./Steps/LoadprofileSelectionStep";

const CREATE_FORM_FIELD_NAMES = [
  "name",
  "type",
  "person",
  "connection",
  "bruttoleistung",
  "installedCapacity",
  "commissioning",
  "decommissioning"
];

const ADDITIONAL_CREATION_STEPS_BY_PRODUCT = {
  [Product.Analyzer]: [
    CREATE_STEPS.LOADPROFILE_SELECT,
    CREATE_STEPS.BUSINESS_RULES_FORM,
    CREATE_STEPS.FINANCIAL_DATA_FORM,
    CREATE_STEPS.DELIVERY_CONSTRAINTS,
    CREATE_STEPS.CONFIRMATION_PAGE
  ],
  [Product.Manager]: [
    CREATE_STEPS.ANLAGE_TAX_FORM,
    CREATE_STEPS.BUSINESS_RULES_FORM,
    CREATE_STEPS.CONFIRMATION_PAGE
  ]
};

const INITIAL_CREATION_STEPS = [
  CREATE_STEPS.MASTR_IMPORT_CONFIRMATION_PAGE,
  CREATE_STEPS.CREATE_FORM
];

interface GeneratorWizardProps {
  buttonContainer?: HTMLElement;
  product: Product;
  siteId: number;
  variantId: number;
  onClose: () => void;
  onUpdateModalTitle: (title: ReactNode) => void;
  graphCoordinates: { x: number; y: number };
}

function GeneratorWizard(props: GeneratorWizardProps) {
  const {
    buttonContainer,
    product,
    siteId,
    variantId,
    onClose,
    onUpdateModalTitle,
    graphCoordinates
  } = props;
  const [step, setStep] = useState(CREATE_STEPS.IMPORT_OR_CREATE);
  const [componentId, setComponentId] = useState<number | undefined>();
  const [componentName, setComponentName] = useState<string | undefined>();
  const [unhandledComponentIds, setUnhandledComponentIds] = useState<
    Array<number>
  >([]);
  const [responseData, setResponseData] = useState<Record<string, Generator>>(
    {}
  );
  const [
    numTimesBusinessRulesFormTriggered,
    setNumTimesBusinessRulesFormTriggered
  ] = useState(0);
  const [maStRImportTaskId, setMaStRImportTaskId] = useState<
    string | undefined
  >();
  const [batchImportTaskId, setBatchImportTaskId] = useState<
    string | undefined
  >();
  const {
    data: anlageStromstgData,
    isLoading: isAnlageStromstgDataLoading,
    error: anlageStromstgDataError
  } = useAnlageStromstg(
    componentId ? responseData[componentId]?.anlageStromstg : undefined
  );
  const queryClient = useQueryClient();

  const { data: allFormFields } = useQuery({
    queryKey: ["generator-options", { siteId }],
    queryFn: () => fetchGeneratorOptions(siteId),
    gcTime: 0,
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  async function fetchGeneratorOptions(siteId: number) {
    const optionsUrl: string = urls.api.generators(siteId);
    let response: ApiResponse;

    try {
      response = await api.options(optionsUrl);
    } catch (error) {
      openErrorAlertPopup(error);
      return;
    }

    const postData: FieldsDict = response.data.actions.pOST;

    return postData;
  }

  const selectedFormFields: FieldsArray | undefined = useMemo(() => {
    if (allFormFields) {
      return getFormFieldsFromResponse(CREATE_FORM_FIELD_NAMES, allFormFields);
    }
  }, [allFormFields]);

  useEffect(() => {
    let title: ReactNode | string;

    if (step === CREATE_STEPS.DELIVERY_CONSTRAINTS) {
      title = <DeliveryConstraintTitle create />;
    } else if (componentName) {
      title = `${componentName} konfigurieren`;
    } else {
      title = "Erzeuger erstellen";
    }

    onUpdateModalTitle(title);
  }, [step, componentName, onUpdateModalTitle]);

  function handleChooseManualCreate() {
    setStep(CREATE_STEPS.CREATE_FORM);
  }

  function handleMaStRImportSuccess(taskId: string) {
    setStep(CREATE_STEPS.MASTR_IMPORT_CONFIRMATION_PAGE);
    setMaStRImportTaskId(taskId);
  }

  function handleBatchImportSuccess(taskId: string) {
    setStep(CREATE_STEPS.MASTR_IMPORT_CONFIRMATION_PAGE);
    setBatchImportTaskId(taskId);
  }

  function handleImportSuccessDone(components: Array<Generator>) {
    if (components.length > 0) {
      const newUnhandledComponentIds = components.map((c) => c.id);
      const newResponseData: Record<string, Generator> = _.keyBy(
        components,
        "id"
      );

      setUnhandledComponentIds(newUnhandledComponentIds);
      setResponseData(newResponseData);
      setNextAdditionalStep(newUnhandledComponentIds, newResponseData);
    } else {
      handleConfirmationDone();
    }
  }

  function handleClickCreate(responseData: Generator) {
    const newUnhandledComponentIds = [responseData.id];
    const newResponseData = { [responseData.id]: responseData };

    invalidateCachedData();
    setUnhandledComponentIds(newUnhandledComponentIds);
    setResponseData(newResponseData);
    setNextAdditionalStep(newUnhandledComponentIds, newResponseData);
  }

  function invalidateCachedData() {
    const objectCacheName = getPluralVariableNameFromObjectName(
      ObjectName.Generator
    );

    queryClient.invalidateQueries({
      queryKey: [objectCacheName, { siteOrVariantId: siteId }]
    });
  }

  function getAdditionalSteps() {
    return ADDITIONAL_CREATION_STEPS_BY_PRODUCT[product];
  }

  function getNextAdditionalStep(currentStep: number): number {
    const steps = getAdditionalSteps();

    if (INITIAL_CREATION_STEPS.includes(currentStep)) {
      return steps[0];
    }

    const currentStepIndex = steps.findIndex((s) => s === currentStep);

    if (currentStepIndex >= 0) {
      const nextStepIndex = (currentStepIndex + 1) % steps.length;
      return steps[nextStepIndex];
    }

    return -1;
  }

  function setNextAdditionalStep(
    newUnhandledComponentIds?: Array<number>,
    newResponseData?: Record<string, Generator>
  ) {
    const newOrStateUnhandledComponentIds =
      newUnhandledComponentIds || unhandledComponentIds;
    const newOrStateResponseData = newResponseData || responseData;
    const nextStep = getNextAdditionalStep(step);

    if (nextStep === CREATE_STEPS.CONFIRMATION_PAGE) {
      handleConfirmationDone();
    }

    setStep(nextStep);

    if (
      shouldStartHandlingNextComponent(
        nextStep,
        newOrStateUnhandledComponentIds
      )
    ) {
      setComponentId(newOrStateUnhandledComponentIds[0]);
      setComponentName(
        newOrStateResponseData[newOrStateUnhandledComponentIds[0]].name
      );
      setUnhandledComponentIds(newOrStateUnhandledComponentIds.slice(1));
    }

    if (nextStep === CREATE_STEPS.BUSINESS_RULES_FORM) {
      setNumTimesBusinessRulesFormTriggered(
        numTimesBusinessRulesFormTriggered + 1
      );
    }
  }

  function shouldStartHandlingNextComponent(
    step: number,
    unhandledComponentIds: Array<number>
  ) {
    const steps = getAdditionalSteps();
    const isFirstStep = step === steps[0];

    return isFirstStep && unhandledComponentsExist(unhandledComponentIds);
  }

  function unhandledComponentsExist(unhandledComponentIds: Array<number>) {
    return unhandledComponentIds.length > 0;
  }

  function triggerBusinessRules() {
    setStep(CREATE_STEPS.BUSINESS_RULES_FORM);
    setNumTimesBusinessRulesFormTriggered(
      numTimesBusinessRulesFormTriggered + 1
    );
  }

  function handleBusinessRulesTriggerDone() {
    triggerBusinessRules();
  }

  function handleConfirmationDone() {
    if (unhandledComponentsExist(unhandledComponentIds)) {
      setNextAdditionalStep();
    } else {
      onClose();
      showToast("success", "Der Erzeuger wurde erstellt und konfiguriert.");
    }
  }

  function renderStep() {
    const generatorDetailUrl = componentId
      ? urls.api.generator(componentId)
      : undefined;

    switch (step) {
      case CREATE_STEPS.IMPORT_OR_CREATE:
        return (
          <ImportOrCreateStep
            buttonContainer={buttonContainer}
            objectName={ObjectName.Generator}
            siteId={siteId}
            variantId={variantId}
            onBatchImportSuccess={handleBatchImportSuccess}
            onCancel={onClose}
            onChooseManualCreate={handleChooseManualCreate}
            onImportSuccess={handleMaStRImportSuccess}
          />
        );
      case CREATE_STEPS.CREATE_FORM: {
        return (
          <CreateFormStep
            buttonContainer={buttonContainer}
            graphCoordinates={graphCoordinates}
            selectedFormFields={selectedFormFields}
            siteId={siteId}
            variantId={variantId}
            onClickCreate={handleClickCreate}
            onClose={onClose}
          />
        );
      }
      case CREATE_STEPS.LOADPROFILE_SELECT:
        if (!componentId) {
          return <AnimatedLoadingIcon />;
        }

        return (
          <LoadprofileSelectionStep
            allFormFields={allFormFields}
            buttonContainer={buttonContainer}
            patchUrl={generatorDetailUrl}
            responseData={responseData[componentId]}
            variantId={variantId}
            onStepDone={setNextAdditionalStep}
          />
        );

      case CREATE_STEPS.ANLAGE_TAX_FORM: {
        if (!componentId) {
          return <AnimatedLoadingIcon />;
        }

        const multiSite = Boolean(
          anlageStromstgData?.hasStandortuebergreifendeVerklammerung
        );

        return (
          <LoadOrError
            error={anlageStromstgDataError}
            loading={isAnlageStromstgDataLoading}
          >
            <AnlageStromstgFlow
              buttonContainer={buttonContainer}
              currentGenerator={responseData[componentId]}
              currentGeneratorIsNew
              multiSite={multiSite}
              shouldSkip={
                responseData[componentId].type !==
                GENERATOR_TYPES.EEG_ANLAGE_WIND
              }
              siteId={siteId}
              variantId={variantId}
              onSubmit={setNextAdditionalStep}
            />
          </LoadOrError>
        );
      }
      case CREATE_STEPS.BUSINESS_RULES_FORM: {
        if (!componentId) {
          return <AnimatedLoadingIcon />;
        }

        const dataUrls = [urls.api.triggerGeneratorRules(componentId)];
        return (
          <DynamicFormWithLoader
            buttonContainer={buttonContainer}
            dataUrls={dataUrls}
            doWhenTaskSuccessfulThenAbortLoading={setNextAdditionalStep}
            key={numTimesBusinessRulesFormTriggered}
            onDone={handleBusinessRulesTriggerDone}
          />
        );
      }
      case CREATE_STEPS.FINANCIAL_DATA_FORM:
        return (
          <FinancialDataStep
            allFormFields={allFormFields}
            buttonContainer={buttonContainer}
            detailUrl={generatorDetailUrl}
            onStepDone={setNextAdditionalStep}
          />
        );
      case CREATE_STEPS.DELIVERY_CONSTRAINTS:
        return (
          <LoadOrError loading={!componentId}>
            {componentId && (
              <DeliveryConstraintsWizardStep
                buttonContainer={buttonContainer}
                componentId={componentId}
                siteId={siteId}
                onDone={setNextAdditionalStep}
              />
            )}
          </LoadOrError>
        );
      case CREATE_STEPS.MASTR_IMPORT_CONFIRMATION_PAGE: {
        if (!maStRImportTaskId && !batchImportTaskId) {
          return <AnimatedLoadingIcon />;
        }

        if (maStRImportTaskId) {
          return (
            <GeneratorMaStRImportSuccessWidget
              buttonContainer={buttonContainer}
              taskId={maStRImportTaskId}
              onDoneStep={handleImportSuccessDone}
              onObjectCreated={invalidateCachedData}
            />
          );
        } else if (batchImportTaskId) {
          return (
            <GeneratorBatchImportSuccessWidget
              buttonContainer={buttonContainer}
              taskId={batchImportTaskId}
              onDoneStep={handleImportSuccessDone}
              onObjectCreated={invalidateCachedData}
            />
          );
        }

        break;
      }
      default:
        console.error("Unexpected step error!");
        return <div />;
    }
  }

  return <div className="GeneratorWizard">{renderStep()}</div>;
}

export { GeneratorWizard, GeneratorWizardProps };
