import type { ComboboxItem } from "@mantine/core";
import { Group, MultiSelect, Stack, Title } from "@mantine/core";
import type { DatesRangeValue } from "@mantine/dates";
import { MonthPickerInput } from "@mantine/dates";
import { useLocalStorage } from "@mantine/hooks";
import type { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { usePersonGenerators } from "../../../../hooks/usePersonGenerators";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { sortBackendDates } from "../../../../utils/dates/sortBackendDates";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import type { ChartTableSwitchState } from "../../ChartTableSwitch/ChartTableSwitch";
import { ChartTableSwitch } from "../../ChartTableSwitch/ChartTableSwitch";
import { useGeneratorEnergyRevenuesData } from "../../hooks/useGeneratorEnergyRevenuesData";
import { EnergyRevenuesView } from "../EnergyRevenuesView";
import { getMinOrMaxDateFromEnergyRevenues } from "../utils/getMinOrMaxDateFromEnergyRevenues";

interface GeneratorSectionProps {
  companyId: number;
  companyLabel: string;
  selectedDateRange: [DateTime, DateTime | null];
  handleDatePickerChange: (dates: DatesRangeValue) => void;
}

function GeneratorSection({
  companyId,
  companyLabel,
  selectedDateRange,
  handleDatePickerChange
}: GeneratorSectionProps) {
  const [view, setView] = useState<ChartTableSwitchState>("chart");
  const {
    data: generators,
    error: generatorsError,
    isLoading: generatorsAreLoading
  } = usePersonGenerators(companyId);

  const generatorOptions = useMemo(
    () =>
      generators?.map<ComboboxItem>((generator) => ({
        label: `${generator.name} (${generator.siteName})`,
        value: generator.id.toString()
      })),
    [generators]
  );

  // Replace the useState for selectedGenerators with useLocalStorage
  const [selectedGenerators, setSelectedGenerators] = useLocalStorage<
    Array<string> | undefined
  >({
    key: "EnergyRevenueSelectedGenerators",
    defaultValue: generatorOptions?.length
      ? [generatorOptions[0].value]
      : undefined
  });

  useEffect(() => {
    // If the selected generators(from localstorage) are not in the generator options, show the first generator
    if (
      !generatorsAreLoading &&
      selectedGenerators?.some(
        (generatorId) =>
          !generatorOptions?.some((option) => option.value === generatorId)
      )
    ) {
      setSelectedGenerators(
        generatorOptions?.length ? [generatorOptions[0].value] : undefined
      );
    }
  }, [
    generatorOptions,
    selectedGenerators,
    setSelectedGenerators,
    generatorsAreLoading
  ]);

  const selectedGeneratorsLabel = useMemo(() => {
    if (!selectedGenerators) {
      return "";
    }

    return selectedGenerators
      .map((generatorId) => {
        const generator = generators?.find(
          (generator) => generator.id === Number(generatorId)
        );

        return generator ? `${generator.name}` : "";
      })
      .join(", ");
  }, [generators, selectedGenerators]);

  const {
    energyRevenuesGeneratorData,
    isLoading: isEnergyRevenuesGeneratorDataLoading,
    error: energyRevenuesGeneratorDataError
  } = useGeneratorEnergyRevenuesData(
    selectedGenerators?.map((generatorId) => Number(generatorId)) ?? []
  );

  const formattedGeneratorData = useMemo(() => {
    return [...(energyRevenuesGeneratorData ?? [])]
      .filter((energyRevenues) => {
        const revenuesDate = backendDateOrDateTimeToLuxonDateTime(
          energyRevenues.period_start
        );

        return (
          selectedDateRange[0] <= revenuesDate &&
          (selectedDateRange[1] === null ||
            revenuesDate <= selectedDateRange[1])
        );
      })
      .sort((revenuesA, revenuesB) =>
        sortBackendDates(revenuesA.period_start, revenuesB.period_start)
      );
  }, [energyRevenuesGeneratorData, selectedDateRange]);

  return (
    <Stack gap="sm">
      <Title order={5}>Anlagenübersicht</Title>

      <Group justify="space-between">
        <Group gap="sm">
          <MultiSelect
            className="generator-select"
            clearable
            data={generatorOptions}
            disabled={generatorsAreLoading || generators?.length === 0}
            label="Anlagen"
            placeholder="Anlagen auswählen"
            searchable
            size="xs"
            value={selectedGenerators}
            w={624}
            onChange={setSelectedGenerators}
          />
          <MonthPickerInput
            allowSingleDateInRange
            disabled={
              generatorsAreLoading || energyRevenuesGeneratorData?.length === 0
            }
            label="Berechneter Zeitraum"
            maxDate={getMinOrMaxDateFromEnergyRevenues(
              energyRevenuesGeneratorData ?? [],
              "max"
            )?.toJSDate()}
            minDate={getMinOrMaxDateFromEnergyRevenues(
              energyRevenuesGeneratorData ?? [],
              "min"
            )?.toJSDate()}
            size="xs"
            type="range"
            value={[
              selectedDateRange[0].toJSDate(),
              selectedDateRange[1]?.toJSDate() || null
            ]}
            onChange={handleDatePickerChange}
          />
        </Group>
        <ChartTableSwitch setValue={setView} value={view} />
      </Group>

      <LoadOrError
        error={generatorsError || energyRevenuesGeneratorDataError}
        loading={generatorsAreLoading || isEnergyRevenuesGeneratorDataLoading}
      >
        <EnergyRevenuesView
          energyRevenuesData={formattedGeneratorData}
          exportFileName={`${companyLabel} - ${selectedGeneratorsLabel}`}
          view={view}
        />
      </LoadOrError>
    </Stack>
  );
}

export { GeneratorSection };
