import type { MenuItemProps } from "./MenuItem";
import { MenuItem } from "./MenuItem";

interface AdminItemProps extends MenuItemProps {
  adminUrl?: string;
}

function AdminItem({ adminUrl, ...props }: AdminItemProps) {
  if (!adminUrl) {
    return null;
  }

  return <MenuItem {...props} external url={adminUrl} />;
}

export { AdminItem };
