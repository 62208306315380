import * as Sentry from "@sentry/browser";
import { generatePath, Link } from "react-router-dom";
import { IconName } from "../../BuildingBlocks/Icon/types";
import type { MenuItemData } from "../Menu.types";
import "./MenuItem.scss";
import { MenuItemContent } from "./MenuItemContent";
import { OnboardingCounter } from "./OnboardingCounter";

interface MenuItemProps extends MenuItemData {
  active: boolean;
  external?: boolean;
  projectId?: string | null;
  simulationId?: string;
  siteId?: number | null;
  variantId?: number | null;
}

function MenuItem({
  active,
  url,
  external,
  icon,
  label,
  projectId,
  simulationId,
  siteId,
  variantId,
  hidden
}: MenuItemProps) {
  const showOnboardingItem = label === "Onboarding" && variantId;
  let path: string;

  // mk: I don't think this is a great way to do this
  // basically, generatePath will throw an error if some params are missing (e.g. not loaded)
  // but all we really want to do is not show those links
  // solutions I can think of increase code complexity a lot - this is very simple
  try {
    if (!url) {
      throw "Missing menu item url";
    }

    if (icon === IconName.OptiCharge) {
      path = url;
    } else {
      path = generatePath(url, {
        projectId,
        simulationId,
        siteId,
        variantId
      });
    }
  } catch (error) {
    // as per comment above, params can be missing during load
    // however, URL should never be missing - it means something was not implemented in code
    if (!url) {
      console.error("Missing menu item params", error);
      Sentry.captureException(error);
    }

    return null;
  }

  if (hidden) {
    return null;
  }

  return (
    <div className="MenuItemStyle">
      {external ? (
        <a href={path} rel="noreferrer" target="_blank">
          <MenuItemContent icon={icon} label={label} />
        </a>
      ) : (
        <Link aria-current={active ? "page" : undefined} to={path}>
          <MenuItemContent icon={icon} label={label} />
        </Link>
      )}
      {showOnboardingItem && <OnboardingCounter variantId={variantId} />}
    </div>
  );
}

export { MenuItem, MenuItemProps };
