import { Alert, Box } from "@mantine/core";
import { IconExclamationCircle } from "@tabler/icons-react";
import { Link, useParams } from "react-router-dom";
import { pathToLoggingTab } from "../../../AnalyzeView/AnalyzeView";
import type { VariantMeteringConceptConfiguration } from "../VariantComparison";

interface AlertRowProps {
  meteringConceptConfiguration: Record<
    number,
    VariantMeteringConceptConfiguration
  >;
  meteringConceptVariantIds: Array<number>;
}

function AlertRow({
  meteringConceptConfiguration,
  meteringConceptVariantIds
}: AlertRowProps) {
  const { projectId } = useParams();

  return (
    <>
      <Box />
      {meteringConceptVariantIds.map((variantId) => (
        <Box key={variantId} p="xs">
          {meteringConceptConfiguration[variantId].showAlert && (
            <Alert color="yellow" icon={<IconExclamationCircle />}>
              Bei der Bewertung dieser Variante sind Warnungen aufgetreten.{" "}
              <br /> Sie können diese im{" "}
              <Link to={pathToLoggingTab(projectId, variantId)}>Protokoll</Link>{" "}
              einsehen.
            </Alert>
          )}
        </Box>
      ))}
    </>
  );
}

export { AlertRow };
