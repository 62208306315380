import type { MenuItemProps } from "./MenuItem";
import { MenuItem } from "./MenuItem";

interface OptiChargeItemProps extends MenuItemProps {
  optiChargeUrl?: string;
}

function OptiChargeItem({ optiChargeUrl, ...props }: OptiChargeItemProps) {
  if (!optiChargeUrl) {
    return null;
  }
  return <MenuItem {...props} external url={optiChargeUrl} />;
}

export { OptiChargeItem };
