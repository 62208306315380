import { AppShell, Box, Center, SimpleGrid, Stack } from "@mantine/core";
import { NavLink as RRNavLink, useLocation } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";

import { ROUTES } from "../../../routes";
import type { SiteName } from "../../../utils/enums";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { Brand } from "../../Layout/Brand";
import type { MenuItemData } from "../../Menu/Menu.types";
import { Group } from "../../Menu/Menu.types";
import { MenuItem } from "../../Menu/MenuItem/MenuItem";

import { ModuleSwitchItem } from "../../Menu/MenuItem/ModuleSwitchItem";

declare const SITE_NAME: SiteName;

const ADMIN_DASHBOARD_URLS = {
  CENTRAL_TASK_LIST: "/admin-dashboard/aufgaben",
  PROPERTY: "/admin-dashboard/liegenschaftsuebersicht",
  MSB_PROCESS: "/admin-dashboard/msb-prozess/",
  LOCATION_OVERVIEW: "/admin-dashboard/msb-prozess/lokationsuebersicht",
  METERING_SERVICE_PROVIDER: "/admin-dashboard/msb-prozess/messstellenbetreiber"
} as const;

const MENU_ITEMS: Array<MenuItemData> = [
  {
    url: ADMIN_DASHBOARD_URLS.CENTRAL_TASK_LIST,
    group: Group.ModuleChoice,
    icon: IconName.List,
    label: "Zentrale Aufgabenliste",
    showGroups: [Group.TopLevel, Group.ModuleChoice]
  },
  {
    url: ADMIN_DASHBOARD_URLS.PROPERTY,
    group: Group.ModuleChoice,
    icon: IconName.Projects,
    label: "Liegenschaften",
    showGroups: [Group.TopLevel, Group.ModuleChoice]
  },
  {
    url: ADMIN_DASHBOARD_URLS.MSB_PROCESS,
    group: Group.ModuleChoice,
    icon: IconName.Map,
    label: "MSB Prozess",
    showGroups: [Group.TopLevel, Group.ModuleChoice]
  }
];

export function AdminDashboardLayout({ children }) {
  const location = useLocation();

  function renderTabs() {
    switch (removeTrailingSlash(location.pathname)) {
      case ADMIN_DASHBOARD_URLS.CENTRAL_TASK_LIST:
        return (
          <NavItem>
            <NavLink
              tag={RRNavLink}
              to={ADMIN_DASHBOARD_URLS.CENTRAL_TASK_LIST}
            >
              Aufgaben
            </NavLink>
          </NavItem>
        );
      case ADMIN_DASHBOARD_URLS.PROPERTY:
        return (
          <NavItem>
            <NavLink tag={RRNavLink} to={ADMIN_DASHBOARD_URLS.PROPERTY}>
              Liegenschaftsübersicht
            </NavLink>
          </NavItem>
        );
      case ADMIN_DASHBOARD_URLS.MSB_PROCESS:
      case ADMIN_DASHBOARD_URLS.METERING_SERVICE_PROVIDER:
      case ADMIN_DASHBOARD_URLS.LOCATION_OVERVIEW:
        return (
          <>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                to={ADMIN_DASHBOARD_URLS.LOCATION_OVERVIEW}
              >
                Lokationsübersicht
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                to={ADMIN_DASHBOARD_URLS.METERING_SERVICE_PROVIDER}
              >
                Messstellenbetreiber
              </NavLink>
            </NavItem>
          </>
        );
      default:
      // no default
    }
  }

  return (
    <AppShell
      className="Layout"
      navbar={{ width: 150, breakpoint: 0 }}
      withBorder={false}
    >
      <AppShell.Navbar bg={"rgb(44, 46, 62)"}>
        <div
          style={{
            overflowX: "hidden",
            scrollbarColor: "#777 transparent",
            scrollbarWidth: "thin"
          }}
        >
          <Center bg="var(--brand-logo-background)" mih={80}>
            <Brand
              isAdminDashboardPage
              siteName={SITE_NAME}
              url={ROUTES.productSelectPage}
            />
          </Center>

          {MENU_ITEMS.map((menuItem) => (
            <MenuItem
              active={
                menuItem.url
                  ? location.pathname.includes(
                      removeTrailingSlash(menuItem.url)
                    )
                  : false
              }
              key={menuItem.url}
              {...menuItem}
            />
          ))}
          <ModuleSwitchItem isAdminDashboardPage />
        </div>
      </AppShell.Navbar>
      <AppShell.Main component={SimpleGrid}>
        <Stack gap={0}>
          <Box bg="white" p="sm" pb={0}>
            <Nav className="tabs" pills>
              {renderTabs()}
            </Nav>
          </Box>
          <Box flex={1} p={"sm"}>
            {children}
          </Box>
        </Stack>
      </AppShell.Main>
    </AppShell>
  );
}

function removeTrailingSlash(url: string) {
  return url.replace(/\/$/, "");
}
