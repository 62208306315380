import { Box, Center } from "@mantine/core";
import { DecimalLabel } from "../../../BuildingBlocks/DecimalLabel/DecimalLabel";
import type {
  MeteringConceptYearlyAggregateWithSavings,
  RowConfigurationEntry
} from "./VariantComparisonHeader";

interface ValueRowProps {
  rowConfiguration: RowConfigurationEntry;
  meteringConceptVariantIds: Array<number>;
  yearlyAggregatesWithSavings: Record<
    string,
    MeteringConceptYearlyAggregateWithSavings
  >;
}

function ValueRow({
  rowConfiguration,
  meteringConceptVariantIds,
  yearlyAggregatesWithSavings
}: ValueRowProps) {
  return (
    <>
      <Box key={rowConfiguration.variable} p="xs">
        {rowConfiguration.rowLabel}
      </Box>
      {meteringConceptVariantIds.map((variantId, i) => {
        const value: string | number =
          yearlyAggregatesWithSavings[variantId]?.[
            rowConfiguration.variable
          ] !== undefined
            ? yearlyAggregatesWithSavings[variantId][rowConfiguration.variable]
            : "-";

        return (
          <Center
            bg={i % 2 === 0 ? "#f9f9f9" : undefined}
            key={"${rowConfiguration.variable}-${variantId}"}
          >
            <DecimalLabel
              value={value}
              {...rowConfiguration.labelConfiguration}
            />
          </Center>
        );
      })}
    </>
  );
}

export { ValueRow };
