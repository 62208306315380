import { Divider } from "@mantine/core";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { MenuItemContent } from "./MenuItemContent";

interface ModuleSwitchItemProps {
  isAnalyzerPage?: boolean;
  isManagerPage?: boolean;
  isAdminDashboardPage?: boolean;
  userHasAnalyzerLicense?: boolean;
  userHasManagerLicense?: boolean;
}

function ModuleSwitchItem({
  isAnalyzerPage,
  isManagerPage,
  isAdminDashboardPage,
  userHasAnalyzerLicense,
  userHasManagerLicense
}: ModuleSwitchItemProps) {
  let switchUrl: string, linkText: string;

  if (isAnalyzerPage && userHasManagerLicense) {
    switchUrl = ROUTES.managerStartPage;
    linkText = "Zum Manager";
  } else if (isManagerPage && userHasAnalyzerLicense) {
    switchUrl = ROUTES.analyzerProjectList;
    linkText = "Zum Analyzer";
  } else if (isAdminDashboardPage) {
    switchUrl = ROUTES.admin;
    linkText = "Zum Admin";
  } else {
    return null;
  }

  return (
    <div className="MenuItemStyle">
      <Divider color="#ffffff30" mb={"sm"} />
      {!isAdminDashboardPage ? (
        <Link to={switchUrl}>
          <MenuItemContent icon={IconName.ArrowCircleLeft} label={linkText} />
        </Link>
      ) : (
        <a href={switchUrl}>
          <MenuItemContent icon={IconName.ArrowCircleLeft} label={linkText} />
        </a>
      )}
    </div>
  );
}

export { ModuleSwitchItem };
