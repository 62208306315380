import { Box, Button, Group } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import { merge } from "lodash";
import type {
  MRT_Column,
  MRT_ColumnDef,
  MRT_Row,
  MRT_SortingState
} from "mantine-react-table";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useMemo } from "react";
import { useCurrentUserQuery } from "../../../../hooks/useCurrentUserQuery";
import { getDefaultMRTOptions } from "../../../../mantine/getDefaultMRTOptions";
import { MantineRoundedNumberCell } from "../../../../mantine/mrt/components/cell/MantineRoundedNumberCell";
import { sortBackendDates } from "../../../../utils/dates/sortBackendDates";
import { downloadAsExcel } from "../../../../utils/files/downloadAsExcel";
import removeNonNumberKeys from "../../../../utils/removeNonNumberKeys";
import { showToast } from "../../../../utils/toast";
import { useShouldShowStaffView } from "../../../StaffViewToggle/useShouldShowStaffView";
import { DataVerificationCell } from "../../DataVerificationCell/DataVerificationCell";
import { usePersonEnergyRevenuesVerificationMutations } from "../../hooks/usePersonEnergyRevenuesVerificationMutations";
import type {
  EnergyRevenuesListItem,
  EnergyRevenuesListResponse
} from "../../RevenueMonitoring.types";
import { prepareTableDataForExport } from "../utils/prepareTableDataForExport";
import { sumByKeys } from "../utils/sumByKeys";

const DEFAULT_SORTED: MRT_SortingState = [
  {
    id: "period_start",
    desc: true
  }
];

interface EnergyRevenuesTableProps {
  energyRevenuesData: EnergyRevenuesListResponse;
  dataVerificationColumns?: Array<keyof EnergyRevenuesListItem>;
  companyId?: number;
  exportFileName: string;
}

function EnergyRevenuesTable({
  energyRevenuesData,
  dataVerificationColumns,
  companyId,
  exportFileName
}: EnergyRevenuesTableProps) {
  const isStaff = useShouldShowStaffView();
  const totals = sumByKeys(removeNonNumberKeys(energyRevenuesData));
  const columns = useMemo<Array<MRT_ColumnDef<EnergyRevenuesListItem>>>(
    () => [
      {
        accessorKey: "period_start",
        header: "Zeitraum",
        footer: "Summe",
        sortingFn: (rowA, rowB) =>
          sortBackendDates(
            rowA.original.period_start,
            rowB.original.period_start
          )
      },
      {
        accessorKey: "direktvermarktung_volume",
        header: "Menge Direktvermarktung (kWh)",
        Cell: ({ row, column }) => (
          <CustomTableCell
            column={column}
            dataVerificationColumns={dataVerificationColumns}
            personId={companyId}
            row={row}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell
            unit=" kWh"
            value={totals.direktvermarktung_volume || 0}
          />
        )
      },
      ...(isStaff
        ? [
            {
              accessorKey: "negative_price_revenue",
              header: "Entgangene Marktprämie für § 51-Menge (EUR)",
              Cell: ({ row, column }) => (
                <CustomTableCell
                  column={column}
                  dataVerificationColumns={dataVerificationColumns}
                  personId={companyId}
                  row={row}
                />
              ),
              Footer: () => (
                <MantineRoundedNumberCell
                  unit=" EUR"
                  value={totals.negative_price_revenue || 0}
                />
              )
            }
          ]
        : []),
      {
        accessorKey: "negative_price_volume",
        header: "§ 51 Menge mit negativem Preis (kWh)",
        Cell: ({ row, column }) => (
          <CustomTableCell
            column={column}
            dataVerificationColumns={dataVerificationColumns}
            personId={companyId}
            row={row}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell
            unit=" kWh"
            value={totals.negative_price_volume || 0}
          />
        )
      },
      {
        accessorKey: "direktvermarktung_net_revenue",
        header: "Erlös Direktvermarktung netto (EUR)",
        Cell: ({ row, column }) => (
          <CustomTableCell
            column={column}
            dataVerificationColumns={dataVerificationColumns}
            personId={companyId}
            row={row}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell
            unit=" €"
            value={totals.direktvermarktung_net_revenue || 0}
          />
        )
      },
      {
        accessorKey: "direktvermarktung_fee",
        header: "Direktvermarktungsentgelt (EUR)",
        Cell: ({ row, column }) => (
          <CustomTableCell
            column={column}
            dataVerificationColumns={dataVerificationColumns}
            personId={companyId}
            row={row}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell
            unit=" €"
            value={totals.direktvermarktung_fee || 0}
          />
        )
      },
      {
        accessorKey: "net_revenue_minus_fee",
        header: "Direktvermarktung abzgl. Entgelt (EUR)",
        Cell: ({ row, column }) => (
          <CustomTableCell
            column={column}
            dataVerificationColumns={dataVerificationColumns}
            personId={companyId}
            row={row}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell
            unit=" €"
            value={totals.net_revenue_minus_fee || 0}
          />
        )
      },
      {
        accessorKey: "market_premium_volume",
        header: "Menge Marktprämie (kWh)",
        Cell: ({ row, column }) => (
          <CustomTableCell
            column={column}
            dataVerificationColumns={dataVerificationColumns}
            personId={companyId}
            row={row}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell
            unit=" kWh"
            value={totals.market_premium_volume || 0}
          />
        )
      },
      {
        accessorKey: "market_premium_revenue",
        header: "Erlös Marktprämie (EUR)",
        Cell: ({ row, column }) => (
          <CustomTableCell
            column={column}
            dataVerificationColumns={dataVerificationColumns}
            personId={companyId}
            row={row}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell
            unit=" €"
            value={totals.market_premium_revenue || 0}
          />
        )
      },
      {
        accessorKey: "revenue_per_kwh",
        header: "∅ Erlös in EUR je kWh",
        Cell: ({ row }) =>
          !row.original.net_revenue_minus_fee ||
          !row.original.direktvermarktung_volume ? (
            ""
          ) : (
            <MantineRoundedNumberCell
              decimalScale={3}
              value={
                row.original.net_revenue_minus_fee /
                row.original.direktvermarktung_volume
              }
            />
          )
      }
    ],
    [totals, dataVerificationColumns, companyId, isStaff]
  );

  const defaultOptions = getDefaultMRTOptions<EnergyRevenuesListItem>({
    emptyRowsFallbackText: "Keine Daten vorhanden."
  });

  const tableOptions = merge({}, defaultOptions, {
    enableColumnActions: true,
    enableTopToolbar: false,
    mantinePaperProps: {
      p: 0,
      withBorder: false,
      shadow: "none"
    },
    initialState: {
      pagination: {
        pageSize: 15
      },
      sorting: DEFAULT_SORTED
    }
  });

  const table = useMantineReactTable({
    ...tableOptions,
    columns,
    data: energyRevenuesData
  });

  return (
    <Box>
      <Group justify="end" mb="md">
        <Button
          disabled={!energyRevenuesData.length}
          leftSection={<IconDownload />}
          variant="filled"
          onClick={() => {
            const tableData = prepareTableDataForExport(table, totals);
            // dates for filename
            const firstDate = tableData[1][0]; // no date in header
            const lastDate = tableData.at(-2)?.[0]; // no date in footer
            downloadAsExcel(
              tableData,
              `${exportFileName}-${firstDate}-${lastDate}`
            );
          }}
        >
          Exportieren
        </Button>
      </Group>
      <MantineReactTable table={table} />
    </Box>
  );
}

function CustomTableCell({
  row,
  column,
  dataVerificationColumns,
  personId
}: {
  row: MRT_Row<EnergyRevenuesListItem>;
  column: MRT_Column<EnergyRevenuesListItem>;
  personId?: number;
  dataVerificationColumns?: Array<string>;
}) {
  if (!column.id) return null;
  if (dataVerificationColumns?.includes(column.id) && personId) {
    return (
      <DataVerificationTableCell
        column={column}
        personId={personId}
        row={row}
      />
    );
  }
  return <MantineRoundedNumberCell value={row.original[column.id]} />;
}

type DataVerificationTableCellProps = {
  row: MRT_Row<EnergyRevenuesListItem>;
  column: MRT_Column<EnergyRevenuesListItem>;
  personId: number;
};

function DataVerificationTableCell({
  row,
  column,
  personId
}: DataVerificationTableCellProps) {
  const { currentUser, error: userError } = useCurrentUserQuery();

  const { personEnergyRevenuesVerificationMutation } =
    usePersonEnergyRevenuesVerificationMutations(personId);

  function handleChange(newValue) {
    if (!currentUser) return showToast("error", userError);

    personEnergyRevenuesVerificationMutation
      .mutateAsync({
        state: newValue,
        period_start: row.original.period_start,
        lastChangeAuthor: currentUser.name,
        lastChangeDate: new Date().toISOString(),
        [column.id as keyof EnergyRevenuesListItem]:
          row.original[column.id as keyof EnergyRevenuesListItem]
      })
      .catch((error) => {
        showToast("error", error);
      });
  }

  return (
    <DataVerificationCell
      value={Number(row.original[column.id as keyof EnergyRevenuesListItem])}
      onChange={handleChange}
    />
  );
}
export { EnergyRevenuesTable, EnergyRevenuesTableProps };
