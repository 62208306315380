import { useCallback } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { MENU_ITEMS } from "./Menu.constants";
import { ModuleSwitchItem } from "./MenuItem/ModuleSwitchItem";
import type { MenuRouteProps } from "./MenuRoute/MenuRoute";
import { MenuRoute } from "./MenuRoute/MenuRoute";

interface MenuProps {
  isStaff?: boolean;
  adminUrl?: string;
  userHasAnalyzerLicense: boolean;
  userHasManagerLicense: boolean;
}

function Menu({
  isStaff,
  adminUrl,
  userHasAnalyzerLicense,
  userHasManagerLicense
}: MenuProps) {
  const MenuRouteWithCommonProps = useCallback(
    (props: Omit<MenuRouteProps, "isStaff" | "adminUrl">) => {
      return <MenuRoute adminUrl={adminUrl} isStaff={isStaff} {...props} />;
    },
    [isStaff, adminUrl]
  );

  return (
    <>
      <Routes>
        <Route path="/">
          <Route
            element={
              <MenuRouteWithCommonProps
                menuItemForRoute={MENU_ITEMS.PRODUCT_SELECT_PAGE}
              />
            }
            index
          />
          <Route
            element={
              <MenuRouteWithCommonProps
                menuItemForRoute={MENU_ITEMS.ANALYZER_PROJECT_LIST}
              />
            }
            path="analyzer/projekte"
          />
          <Route path="projekte/:projectId">
            <Route path="varianten/:variantId">
              <Route
                element={
                  <MenuRouteWithCommonProps
                    menuItemForRoute={MENU_ITEMS.ANALYZER_VARIANT_STRUCTURE}
                  />
                }
                path="analyzer/*"
              />
              <Route
                element={
                  <MenuRouteWithCommonProps
                    menuItemForRoute={MENU_ITEMS.ANALYZER_VARIANT_STRUCTURE}
                  />
                }
                path="bewertung/:evaluationId"
              />
              <Route
                element={
                  <MenuRouteWithCommonProps
                    menuItemForRoute={MENU_ITEMS.ANALYSE}
                  />
                }
                path="analyse/*"
              />
              <Route
                element={
                  <MenuRouteWithCommonProps
                    menuItemForRoute={MENU_ITEMS.VARIANT_COMPARISON}
                  />
                }
                path="variantenvergleich/*"
              />
              <Route
                element={
                  <MenuRouteWithCommonProps
                    menuItemForRoute={MENU_ITEMS.REPORT}
                  />
                }
                path="ergebnisreport/*"
              />
            </Route>
            <Route
              element={
                <MenuRouteWithCommonProps
                  menuItemForRoute={MENU_ITEMS.ANALYZER_PROJECT_LIST}
                />
              }
              path="lastprofil-manager/*"
            />
            <Route
              element={
                <MenuRouteWithCommonProps
                  menuItemForRoute={MENU_ITEMS.SIMULATION}
                />
              }
              path="simulation/:simulationId"
            />
          </Route>
          <Route
            element={
              <MenuRouteWithCommonProps
                menuItemForRoute={MENU_ITEMS.MANAGER_START_PAGE}
              />
            }
            path="manager/startseite"
          />
          <Route path="manager/projekte">
            <Route
              element={
                <MenuRouteWithCommonProps
                  menuItemForRoute={MENU_ITEMS.MANAGER_PROJECT_LIST}
                />
              }
              index
            />
            <Route element={<Outlet />} path=":projectId">
              <Route
                element={
                  <MenuRouteWithCommonProps
                    menuItemForRoute={MENU_ITEMS.MANAGER_PROJECT_VIEW}
                  />
                }
                path=":projectPage/*"
              />
              <Route
                element={
                  <MenuRouteWithCommonProps
                    menuItemForRoute={MENU_ITEMS.MANAGER_VARIANT_STRUCTURE}
                  />
                }
                path="liegenschaften/:siteId/*"
              />
              <Route
                element={
                  <MenuRouteWithCommonProps
                    menuItemForRoute={MENU_ITEMS.TODOS}
                  />
                }
                path="aufgaben/*"
              />
              <Route
                element={
                  <MenuRouteWithCommonProps
                    menuItemForRoute={MENU_ITEMS.ONBOARDING}
                  />
                }
                path="onboarding/*"
              />
              <Route
                element={
                  <MenuRouteWithCommonProps
                    menuItemForRoute={MENU_ITEMS.DELIVERY_OVERVIEW}
                  />
                }
                path="lieferungen-uebersicht/*"
              />
              <Route
                element={
                  <MenuRouteWithCommonProps
                    menuItemForRoute={MENU_ITEMS.GUARANTEE_OF_ORIGIN}
                  />
                }
                path="herkunftsnachweis/*"
              />
              <Route
                element={
                  <MenuRouteWithCommonProps
                    menuItemForRoute={
                      MENU_ITEMS.PERFORMANCE_MONITORING_OVERVIEW
                    }
                  />
                }
                path="performance-monitoring/*"
              />
              <Route
                element={
                  <MenuRouteWithCommonProps
                    menuItemForRoute={MENU_ITEMS.ENERGY_DATA}
                  />
                }
                path="energiedaten/*"
              />
              <Route
                element={
                  <MenuRouteWithCommonProps
                    menuItemForRoute={MENU_ITEMS.PARKABRECHNUNG}
                  />
                }
                path="parkabrechnung/*"
              />
              <Route
                element={
                  <MenuRouteWithCommonProps
                    menuItemForRoute={
                      MENU_ITEMS.DIREKTVERMARKTUNG_PROJECT_LEVEL
                    }
                  />
                }
                path="direktvermarktung/*"
              />
              <Route
                element={
                  <MenuRouteWithCommonProps
                    menuItemForRoute={MENU_ITEMS.PARAGRAPH6}
                  />
                }
                path="paragraph6/*"
              />
              <Route
                element={
                  <MenuRouteWithCommonProps
                    menuItemForRoute={
                      MENU_ITEMS.REVENUE_MONITORING_PROJECT_LEVEL
                    }
                  />
                }
                path="erloesmonitoring/*"
              />
            </Route>
          </Route>
          <Route element={<Outlet />} path="manager/liegenschaften/*" />
          <Route
            element={
              <MenuRouteWithCommonProps
                menuItemForRoute={MENU_ITEMS.API_SETTINGS}
              />
            }
            path="api-einstellungen/:projectId?"
          />
          <Route
            element={
              <MenuRouteWithCommonProps
                menuItemForRoute={MENU_ITEMS.PRODUCT_SELECT_PAGE}
              />
            }
            path="einstellungen"
          />
        </Route>
        <Route
          element={
            <MenuRouteWithCommonProps
              menuItemForRoute={MENU_ITEMS.DIREKTVERMARKTUNG}
            />
          }
          path="manager/direktvermarktung/*"
        />
        <Route
          element={
            <MenuRouteWithCommonProps menuItemForRoute={MENU_ITEMS.HISTORY} />
          }
          path="manager/history/:projectId?"
        />
        <Route
          element={
            <MenuRouteWithCommonProps
              menuItemForRoute={MENU_ITEMS.REVENUE_MONITORING}
            />
          }
          path="manager/erloesmonitoring/*"
        />
      </Routes>
      <Routes>
        <Route element={<Outlet />} path="/">
          <Route element={<Outlet />} path="analyzer">
            <Route path="projekte">
              <Route element={<Outlet />} index />
              <Route
                element={
                  <ModuleSwitchItem
                    isAnalyzerPage
                    userHasAnalyzerLicense={userHasAnalyzerLicense}
                    userHasManagerLicense={userHasManagerLicense}
                  />
                }
                path="*"
              />
            </Route>
            <Route
              element={
                <ModuleSwitchItem
                  isAnalyzerPage
                  userHasAnalyzerLicense={userHasAnalyzerLicense}
                  userHasManagerLicense={userHasManagerLicense}
                />
              }
              path="*"
            />
          </Route>
          <Route
            element={
              <ModuleSwitchItem
                isAnalyzerPage
                userHasAnalyzerLicense={userHasAnalyzerLicense}
                userHasManagerLicense={userHasManagerLicense}
              />
            }
            path="projekte/:projectId/varianten/:variantId/*"
          />
          <Route path="manager/projekte">
            <Route element={<Outlet />} index />
            <Route
              element={
                <ModuleSwitchItem
                  isManagerPage
                  userHasAnalyzerLicense={userHasAnalyzerLicense}
                  userHasManagerLicense={userHasManagerLicense}
                />
              }
              path="*"
            />
          </Route>
          <Route element={<Outlet />} path="*" />
        </Route>
      </Routes>
    </>
  );
}

export { Menu };
