import { keyBy } from "lodash";
import urls from "../../urls";
import type { Variant } from "../../utils/backend-types";
import { getDataUrlsForSelectedVariantIds } from "../../utils/getDataUrlsForSelectedVariantIds";
import VariantComparison from "../ProjectManager/VariantComparison/VariantComparison";

interface AssessmentPageProps {
  displayNames: Record<number, string>;
  selectedVariantIds: Array<number>;
  variants: Array<Variant>;
  isAnalyzeView?: boolean;
  onMoveVariantLeft?: (variantId: number) => void;
  onMoveVariantRight?: (variantId: number) => void;
}

function AssessmentPage({
  displayNames,
  selectedVariantIds,
  variants,
  isAnalyzeView = false,
  onMoveVariantLeft,
  onMoveVariantRight
}: AssessmentPageProps) {
  const selectedDataUrls = getDataUrlsForSelectedVariantIds(
    selectedVariantIds,
    urls.api.meteringConcept
  );

  const variantsById = keyBy(variants, "id");

  return (
    <VariantComparison
      columnHeaders={displayNames}
      dataUrls={selectedDataUrls}
      isAnalyzeView={isAnalyzeView}
      selectedVariantIds={selectedVariantIds}
      variants={variantsById}
      onMoveLeft={onMoveVariantLeft}
      onMoveRight={onMoveVariantRight}
    />
  );
}

export { AssessmentPage };
