import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import type { ApiResponse } from "../../api";
import api from "../../api";
import { useSiteCategories } from "../../hooks/useSiteCategories";
import { ROUTES } from "../../routes";
import urls from "../../urls";
import type { Meter } from "../../utils/backend-types";
import { MeasurementType, ObjectName } from "../../utils/enums";
import { getLinkToComponentEditModal } from "../../utils/getLinkToComponentEditModal";
import { getPluralVariableNameFromObjectName } from "../../utils/getPluralVariableNameFromObjectName";
import { showToast } from "../../utils/toast";
import { METER_EDIT_TABS } from "../ComponentListContainer/ComponentList/ComponentEditWizard/Data/Meter";
import {
  CustomForm,
  getFormFieldsFromResponse
} from "../CustomForm/CustomForm";
import type { FormFieldValue } from "../DynamicForm/FormItems/FormField/FormField";
import FormItems from "../DynamicForm/FormItems/FormItems";
import { withCreatableDropdown } from "../DynamicForm/FormItems/withCreatableDropdown";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import type { FieldsArray, FieldsDict } from "../OptionsForm/OptionsForm";
import { useShouldShowStaffView } from "../StaffViewToggle/useShouldShowStaffView";
import {
  MARKET_LOCATION_FORM_FIELD_NAMES,
  METERING_LOCATION_FORM_FIELD_NAMES as METERING_LOCATION_FORM_FIELD_NAMES_GLOBAL,
  formFieldNames,
  getCustomerFields
} from "./meterMarketFormFields";
import "./MeterWizard.scss";

export const CREATE_FORM_FIELD_NAMES = [
  "name",
  "medium",
  "measurementType",
  "frequency",
  "meteringDirection",
  "meteringLocation",
  "marketLocationFeedin",
  "marketLocationFeedout",
  "number",
  "subMeteringSystem",
  "isCalibrated",
  "calibrationValidUntil",
  "conversionFactor"
];

interface MeterWizardProps {
  siteId: number;
  variantId: number;
  buttonContainer?: HTMLElement;
  onClose: () => void;
  graphCoordinates: { x: number; y: number };
}

function MeterWizard({
  siteId,
  buttonContainer,
  onClose,
  graphCoordinates
}: MeterWizardProps) {
  const { siteCategories } = useSiteCategories(siteId ?? null);
  const navigate = useNavigate();
  const managerMatch = useMatch(ROUTES.managerVariantStructure + "/*");
  const [meterData, setMeterData] = useState<Meter | undefined>(undefined);
  const [measurementType, setMeasurementType] = useState<
    FormFieldValue | undefined
  >(undefined);
  const queryClient = useQueryClient();
  const isPremiumSite = siteCategories?.find(
    (site) => site.id === siteId
  )?.is_premium;
  const { data: allFormFields } = useQuery({
    queryKey: ["meter-options", { siteId }],
    queryFn: () => fetchMeterOptions(siteId),
    gcTime: 0,
    refetchInterval: false,
    refetchOnWindowFocus: false
  });
  function handleClose() {
    if (isPremiumSite && meterData && managerMatch) {
      const path = getLinkToComponentEditModal(
        ObjectName.Meter,
        meterData.id,
        managerMatch
      );
      if (path) {
        navigate(path + `/${METER_EDIT_TABS.TAB_METERING_CONCEPT_DATA}`);
      }
    }
    onClose();
    showToast("success", "Der Zähler wurde erstellt.");
  }
  function onInput(fieldName: string, value: FormFieldValue) {
    if (fieldName === "measurementType") {
      setMeasurementType(value);
    }
  }

  async function fetchMeterOptions(siteId: number) {
    const optionsUrl: string = urls.api.meters(siteId);
    let response: ApiResponse;

    try {
      response = await api.options(optionsUrl);
    } catch (error) {
      openErrorAlertPopup(error);
      return;
    }

    const postData: FieldsDict = response.data.actions.pOST;

    return postData;
  }

  const selectedFormFields: FieldsArray | undefined = useMemo(() => {
    if (allFormFields) {
      const fields = getFormFieldsFromResponse(
        CREATE_FORM_FIELD_NAMES,
        allFormFields
      );

      if (measurementType === MeasurementType.Arbitrary) {
        return fields.filter((field) => field.name !== "frequency");
      } else if (measurementType === MeasurementType.Single) {
        return fields.filter((field) => field.name !== "conversionFactor");
      } else {
        return fields;
      }
    }
  }, [allFormFields, measurementType]);

  function handleClickCreate(responseData: Meter): void {
    const objectCacheName = getPluralVariableNameFromObjectName(
      ObjectName.Meter
    );

    queryClient.invalidateQueries({
      queryKey: [objectCacheName, { siteOrVariantId: siteId }]
    });
    setMeterData(responseData);
    handleClose();
  }

  function renderStep(isStaff?: boolean) {
    const postUrl: string = urls.api.meters(siteId);
    const nonFieldData = {
      site: siteId,
      sketchElement: {
        xPosition: graphCoordinates.x,
        yPosition: graphCoordinates.y
      }
    };
    const meteringLocationsPostUrl = urls.api.meteringLocations(siteId);
    const meteringLocationPutUrlFunc = urls.api.meteringLocation;
    const marketLocationPostUrl = urls.api.marketLocations(siteId);
    const marketLocationPutUrlFunc = urls.api.marketLocation;

    const meteringLocationFormFields = formFieldNames(
      isStaff
        ? METERING_LOCATION_FORM_FIELD_NAMES_GLOBAL
        : getCustomerFields(METERING_LOCATION_FORM_FIELD_NAMES_GLOBAL)
    );

    const marketLocationFormFields = formFieldNames(
      isStaff
        ? MARKET_LOCATION_FORM_FIELD_NAMES
        : getCustomerFields(MARKET_LOCATION_FORM_FIELD_NAMES)
    );

    const FormItemsWithCreatableMeteringLocation = withCreatableDropdown(
      FormItems,
      "meteringLocation",
      meteringLocationsPostUrl,
      meteringLocationPutUrlFunc,
      meteringLocationFormFields,
      nonFieldData
    );

    const FormItemsWithCreatableMeteringLocationAndMaloFeedin =
      withCreatableDropdown(
        FormItemsWithCreatableMeteringLocation as unknown as typeof FormItems,
        "marketLocationFeedin",
        marketLocationPostUrl,
        marketLocationPutUrlFunc,
        marketLocationFormFields,
        nonFieldData
      );

    const FormItemsWithCreatableMeteringLocationAndMaloFeedinAndFeedout =
      withCreatableDropdown(
        FormItemsWithCreatableMeteringLocationAndMaloFeedin as unknown as typeof FormItems,
        "marketLocationFeedout",
        marketLocationPostUrl,
        marketLocationPutUrlFunc,
        marketLocationFormFields,
        nonFieldData
      );

    const CustomFormItemsComponent = (props) => (
      <FormItemsWithCreatableMeteringLocationAndMaloFeedinAndFeedout
        {...props}
      />
    );

    return (
      <CustomForm
        buttonContainer={buttonContainer}
        CustomFormItemsComponent={CustomFormItemsComponent}
        formFields={selectedFormFields}
        nonFieldData={nonFieldData}
        postUrl={postUrl}
        submitButtonText="Erstellen"
        onCancel={onClose}
        onInput={onInput}
        onSubmit={handleClickCreate}
      />
    );
  }
  const isStaff = useShouldShowStaffView();
  return <div className="MeterWizard">{renderStep(isStaff)}</div>;
}

export { MeterWizard };
