import classnames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import urls from "../../urls";
import type { Variant } from "../../utils/backend-types";
import { AssessmentPage } from "../AssessmentPage/AssessmentPage";
import { PageHeader } from "../BuildingBlocks/Layout/PageHeader/PageHeader";
import withVariantSelection from "../PageWithVariantSelection/PageWithVariantSelection";
import { RegulatoryDutiesPage } from "../RegulatoryDutiesPage/RegulatoryDutiesPage";
import VariantSelection from "../VariantSelection";
import "./VariantComparisonView.scss";

const TABS = {
  TAB_FINANCIAL_ASSESSMENT: "finanzielle-bewertung",
  TAB_REGULATORY_DUTIES: "regulatorische-pflichten"
};

export const DEFAULT_SUBPAGE = TABS.TAB_FINANCIAL_ASSESSMENT;

interface VariantComparisonViewProps {
  displayNames: Record<number, string>;
  selectedVariantIds: Array<number>;
  variants: Array<Variant>;
  onMoveVariantLeft: (variantId: number) => void;
  onMoveVariantRight: (variantId: number) => void;
  onChangeSelectedVariantIds: (selectedVariantIds: Array<number>) => string;
}

function VariantComparisonView({
  displayNames,
  selectedVariantIds,
  variants,
  onMoveVariantLeft,
  onMoveVariantRight,
  onChangeSelectedVariantIds
}: VariantComparisonViewProps) {
  const { comparisonPage } = useParams();
  const { search } = useLocation();
  const [cachedTabs, setCachedTabs] = useState([comparisonPage]);
  const [cachedQueryString, setCachedQueryString] = useState(search);

  const isTabCached = useCallback(
    (tab) => {
      return cachedTabs.includes(tab);
    },
    [cachedTabs]
  );

  useEffect(() => {
    if (!isTabCached(comparisonPage)) {
      const newCachedTabs = [...cachedTabs];
      newCachedTabs.push(comparisonPage);
      setCachedTabs(newCachedTabs);
    }
  }, [comparisonPage, cachedTabs, isTabCached]);

  function handleChangeSelectedVariantIds(selectedVariantIds: Array<number>) {
    const newQueryString = onChangeSelectedVariantIds(selectedVariantIds);
    setCachedQueryString(newQueryString);
  }

  const activeTab = comparisonPage;

  return (
    <div className="VariantComparisonView scrollable-main-content">
      <Row>
        <Col className="mb-4">
          <PageHeader title="Variantenvergleich" />
        </Col>
        <Col>
          <VariantSelection
            selectedVariantIds={selectedVariantIds}
            variants={variants}
            onUpdateSelectedVariantIds={(selectedVariantIds) =>
              handleChangeSelectedVariantIds(selectedVariantIds)
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Nav className="tabs" pills>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === TABS.TAB_FINANCIAL_ASSESSMENT
                })}
                tag={Link}
                to={`../${TABS.TAB_FINANCIAL_ASSESSMENT}${cachedQueryString}`}
              >
                Finanzielle Bewertung
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === TABS.TAB_REGULATORY_DUTIES
                })}
                tag={Link}
                to={`../${TABS.TAB_REGULATORY_DUTIES}${cachedQueryString}`}
              >
                Regulatorische Pflichten
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={TABS.TAB_FINANCIAL_ASSESSMENT}>
              {activeTab === TABS.TAB_FINANCIAL_ASSESSMENT ||
              isTabCached(TABS.TAB_FINANCIAL_ASSESSMENT) ? (
                <AssessmentPage
                  displayNames={displayNames}
                  selectedVariantIds={selectedVariantIds}
                  variants={variants}
                  onMoveVariantLeft={onMoveVariantLeft}
                  onMoveVariantRight={onMoveVariantRight}
                />
              ) : null}
            </TabPane>
            <TabPane tabId={TABS.TAB_REGULATORY_DUTIES}>
              {activeTab === TABS.TAB_REGULATORY_DUTIES ||
              isTabCached(TABS.TAB_REGULATORY_DUTIES) ? (
                <RegulatoryDutiesPage
                  displayNames={displayNames}
                  selectedVariantIds={selectedVariantIds}
                />
              ) : null}
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </div>
  );
}

const VariantComparisonViewWithVariantSelection = withVariantSelection(
  VariantComparisonView
);

const VariantComparisonViewWithVariantSelectionWithDataUrls = ({
  projectId,
  ...otherProps
}) => {
  const dataUrls = {
    project: urls.api.projectsDetail(projectId)
  };

  return (
    <VariantComparisonViewWithVariantSelection
      dataUrls={dataUrls}
      {...otherProps}
    />
  );
};

export { VariantComparisonViewWithVariantSelectionWithDataUrls as VariantComparisonView };
