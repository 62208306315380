import { ActionIcon, Center, Group, Space, Text } from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import type { VariantMeteringConceptConfiguration } from "../VariantComparison";

interface TopRowProps {
  meteringConceptConfiguration: Record<
    number,
    VariantMeteringConceptConfiguration
  >;
  meteringConceptVariantIds: Array<number>;
  onMoveLeft?: (variantId: number) => void;
  onMoveRight?: (variantId: number) => void;
}

function TopRow({
  meteringConceptConfiguration,
  meteringConceptVariantIds,
  onMoveLeft,
  onMoveRight
}: TopRowProps) {
  return (
    <>
      <Center bg="brand" c="white" p="xs">
        <Text>Zusammenfassung</Text>
      </Center>
      {meteringConceptVariantIds.map((variantId, i) => (
        <TopColumn
          bgCol={i % 2 === 0 ? "customGrey" : "brand"}
          key={variantId}
          text={meteringConceptConfiguration[variantId].header}
          onClickLeft={
            onMoveLeft && i > 0 ? () => onMoveLeft(variantId) : undefined
          }
          onClickRight={
            onMoveRight && i < meteringConceptVariantIds.length - 1
              ? () => onMoveRight(variantId)
              : undefined
          }
        />
      ))}
    </>
  );
}

interface TopColumnProps {
  text: string;
  bgCol: string;
  onClickLeft?: () => void;
  onClickRight?: () => void;
}

function TopColumn({ text, bgCol, onClickLeft, onClickRight }: TopColumnProps) {
  return (
    <Group
      bg={bgCol}
      c="white"
      gap="0"
      justify="space-between"
      p="xs"
      wrap="nowrap"
    >
      {onClickLeft ? (
        <ActionIcon size="lg" variant="transparent" onClick={onClickLeft}>
          <IconChevronLeft color="white" size="30" />
        </ActionIcon>
      ) : (
        <Space w="xl" />
      )}
      <Text ta="center">{text}</Text>
      {onClickRight ? (
        <ActionIcon size="lg" variant="transparent" onClick={onClickRight}>
          <IconChevronRight color="white" size="30" />
        </ActionIcon>
      ) : (
        <Space w="xl" />
      )}
    </Group>
  );
}

export { TopRow };
