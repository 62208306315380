import { AppShell, Box, SimpleGrid, Stack } from "@mantine/core";
import type { ReactNode } from "react";

import type { CurrentUser } from "../../utils/backend-types";
import type { SiteName } from "../../utils/enums";
import { Menu } from "../Menu/Menu";
import { useShouldShowStaffView } from "../StaffViewToggle/useShouldShowStaffView";
import { Footer } from "./Footer";
import { Header } from "./Header";

import "./Layout.scss";

interface Props {
  currentUser: CurrentUser | undefined;
  siteName: SiteName;
  children: ReactNode;

  toggleShowStaffView: () => void;
}

function Layout({
  children,
  siteName,
  currentUser,
  toggleShowStaffView
}: Props) {
  const shouldShowStaffView = useShouldShowStaffView();

  const {
    admin_url = "",
    has_analyzer_license = false,
    has_manager_license = false
  } = currentUser || {};

  return (
    <AppShell
      className="Layout"
      header={{ height: 80 }}
      navbar={{ width: 150, breakpoint: 0 }}
      withBorder={false}
    >
      <AppShell.Header zIndex={201}>
        <Header
          currentUser={currentUser}
          siteName={siteName}
          toggleShowStaffView={toggleShowStaffView}
        />
      </AppShell.Header>
      <AppShell.Navbar bg={"rgb(44, 46, 62)"}>
        <div
          style={{
            overflowX: "hidden",
            scrollbarColor: "#777 transparent",
            scrollbarWidth: "thin"
          }}
        >
          <Menu
            adminUrl={admin_url ?? undefined}
            isStaff={shouldShowStaffView}
            userHasAnalyzerLicense={has_analyzer_license}
            userHasManagerLicense={has_manager_license}
          />
        </div>
      </AppShell.Navbar>
      <AppShell.Main component={SimpleGrid}>
        <Stack gap={0}>
          <Box bg={"var(--body-background)"} flex={1} p={30}>
            <div id="content-and-subheader">
              <div className="m-content">{children}</div>
            </div>
          </Box>
          <Footer siteName={siteName} />
        </Stack>
      </AppShell.Main>
    </AppShell>
  );
}

export { Layout };
