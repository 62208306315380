import { useQueryClient } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import api from "../../api";
import { useIsMieterstrom } from "../../hooks/useIsMieterstrom";
import urls from "../../urls";
import { CREATE_STEPS } from "../../utils/constants";
import { ObjectName, Product } from "../../utils/enums";
import { getPluralVariableNameFromObjectName } from "../../utils/getPluralVariableNameFromObjectName";
import { showToast } from "../../utils/toast";
import {
  FIELDS_FOR_MIETERSTROM_SITES as CONNECTION_FIELDS_FOR_MIETERSTROM_SITES,
  DEFAULT_FIELDS as DEFAULT_CONNECTION_FIELDS
} from "../ComponentListContainer/ComponentList/ComponentEditWizard/Data/Connection";
import {
  CustomForm,
  getFormFieldsFromResponse
} from "../CustomForm/CustomForm";
import { DeliveryConstraintTitle } from "../DeliveryConstraintsModal/DeliveryConstraintsModal";
import { DeliveryConstraintsWizardStep } from "../DeliveryConstraintsWizardStep/DeliveryConstraintsWizardStep";
import { DynamicFormWithLoader } from "../DynamicForm/DynamicForm";
import FormItems from "../DynamicForm/FormItems/FormItems";
import { withCreatableDropdown } from "../DynamicForm/FormItems/withCreatableDropdown";
import { withLoadprofileFields } from "../DynamicForm/FormItems/withLoadprofileFields";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import { CREATE_FORM_FIELD_NAMES_REDUCED as PERSON_FORM_FIELD_NAMES } from "../PersonWizard/PersonWizard";
import "./ConsumerWizard.scss";

const FormItemsWithLoadprofileFields = withLoadprofileFields(FormItems);

const CREATE_FORM_FIELD_NAMES = [
  "name",
  "type",
  "person",
  "connection",
  "electricityPrice"
];

function ConsumerWizard({
  product,
  siteId,
  variantId,
  buttonContainer,
  onClose,
  onUpdateModalTitle,
  graphCoordinates
}) {
  const [step, setStep] = useState(CREATE_STEPS.CREATE_FORM);
  const [allFormFields, setAllFormFields] = useState({});
  const [consumer, setConsumer] = useState(null);
  const [
    numTimesBusinessRulesFormTriggered,
    setNumTimesBusinessRulesFormTriggered
  ] = useState(0);
  const queryClient = useQueryClient();
  const consumerId = consumer ? consumer.id : null;
  const consumerName = consumer ? consumer.name : null;
  const { data: siteIsMieterstrom } = useIsMieterstrom(siteId);

  useEffect(() => {
    let title;

    if (step === CREATE_STEPS.DELIVERY_CONSTRAINTS) {
      title = <DeliveryConstraintTitle create />;
    } else if (consumerName) {
      title = `${consumerName} konfigurieren`;
    } else {
      title = "Verbraucher erstellen";
    }

    onUpdateModalTitle(title);
  }, [step, consumerName, onUpdateModalTitle]);

  function handleClickCreate(responseData) {
    const objectCacheName = getPluralVariableNameFromObjectName(
      ObjectName.Consumer
    );
    queryClient.invalidateQueries({
      queryKey: [objectCacheName, { siteOrVariantId: siteId }]
    });

    setConsumer(responseData);

    if (product === Product.Manager) {
      triggerBusinessRules();
    } else {
      setStep(CREATE_STEPS.LOADPROFILE_SELECT);
    }
  }

  function handleLoadProfileSelectionDone() {
    triggerBusinessRules();
  }

  function triggerBusinessRules() {
    setStep(CREATE_STEPS.BUSINESS_RULES_FORM);
    setNumTimesBusinessRulesFormTriggered(
      numTimesBusinessRulesFormTriggered + 1
    );
  }

  const loadFormFields = useCallback(async () => {
    const optionsUrl = urls.api.consumers(siteId);
    let response;

    try {
      response = await api.options(optionsUrl);
    } catch (error) {
      openErrorAlertPopup(error);
      return;
    }

    setAllFormFields(response.data.actions.pOST);
  }, [siteId]);

  function handleBusinessRulesTriggerDone() {
    triggerBusinessRules();
  }

  function handleBusinessRulesStepDone() {
    if (product === Product.Analyzer) {
      activateDeliveryConstraintsStep();
    } else {
      showSuccessStep();
    }
  }

  function activateDeliveryConstraintsStep() {
    setStep(CREATE_STEPS.DELIVERY_CONSTRAINTS);
  }

  function handleDeliveryConstraintsStepDone() {
    showSuccessStep();
  }

  function showSuccessStep() {
    onClose();
    showToast("success", "Der Verbraucher wurde erstellt.");
  }

  function renderStep() {
    switch (step) {
      case CREATE_STEPS.CREATE_FORM: {
        const postUrl = urls.api.consumers(siteId);
        const nonFieldData = {
          site: siteId,
          sketchElement: {
            xPosition: graphCoordinates.x,
            yPosition: graphCoordinates.y
          }
        };
        const personPostUrl = urls.api.personsByVariantId(variantId);
        const personPutUrlFunc = urls.api.person;
        const personNonFieldData = {
          variant: variantId
        };
        const connectionPostUrl = urls.api.connections(siteId);
        const connectionPutUrlFunc = urls.api.connection;
        const FormItemsWithCreatablePerson = withCreatableDropdown(
          FormItems,
          "person",
          personPostUrl,
          personPutUrlFunc,
          PERSON_FORM_FIELD_NAMES,
          personNonFieldData
        );
        const FormItemsWithCreatablePersonAndConnection = withCreatableDropdown(
          FormItemsWithCreatablePerson,
          "connection",
          connectionPostUrl,
          connectionPutUrlFunc,
          siteIsMieterstrom
            ? CONNECTION_FIELDS_FOR_MIETERSTROM_SITES
            : DEFAULT_CONNECTION_FIELDS,
          nonFieldData
        );

        const CreateFormCustomFormItemsComponent = (props) => (
          <FormItemsWithCreatablePersonAndConnection {...props} />
        );

        const selectedFormFields = getFormFieldsFromResponse(
          CREATE_FORM_FIELD_NAMES,
          allFormFields
        );

        return (
          <CustomForm
            buttonContainer={buttonContainer}
            CustomFormItemsComponent={CreateFormCustomFormItemsComponent}
            formFields={selectedFormFields}
            nonFieldData={nonFieldData}
            postUrl={postUrl}
            submitButtonText="Erstellen"
            onCancel={onClose}
            onSubmit={handleClickCreate}
          />
        );
      }
      case CREATE_STEPS.LOADPROFILE_SELECT: {
        return (
          <LoadProfileStep
            allFormFields={allFormFields}
            buttonContainer={buttonContainer}
            consumer={consumer}
            variantId={variantId}
            onLoadProfileSelectionDone={handleLoadProfileSelectionDone}
          />
        );
      }
      case CREATE_STEPS.BUSINESS_RULES_FORM: {
        if (!consumerId) {
          return null;
        }

        const dataUrls = [urls.api.triggerConsumerRules(consumerId)];
        return (
          <DynamicFormWithLoader
            buttonContainer={buttonContainer}
            dataUrls={dataUrls}
            doWhenTaskSuccessfulThenAbortLoading={handleBusinessRulesStepDone}
            key={numTimesBusinessRulesFormTriggered}
            onDone={handleBusinessRulesTriggerDone}
          />
        );
      }
      case CREATE_STEPS.DELIVERY_CONSTRAINTS:
        return (
          <DeliveryConstraintsWizardStep
            buttonContainer={buttonContainer}
            componentId={consumerId}
            siteId={siteId}
            onDone={handleDeliveryConstraintsStepDone}
          />
        );
      default:
        console.log("Unexpected step error!");
        return <div></div>;
    }
  }

  useEffect(() => {
    setStep(CREATE_STEPS.CREATE_FORM);
    loadFormFields();
  }, [loadFormFields]);

  return <div className="ConsumerWizard">{renderStep()}</div>;
}

function LoadProfileStep({
  consumer,
  allFormFields,
  variantId,
  buttonContainer,
  onLoadProfileSelectionDone
}) {
  if (!consumer) {
    return;
  }

  const patchUrl = urls.api.consumer(consumer.id);
  const selectedFormFields = getFormFieldsFromResponse(
    ["loadprofile", "yearlyEnergy"],
    allFormFields,
    consumer
  );

  const CustomFormItemsComponent = (props) => (
    <FormItemsWithLoadprofileFields
      initialLoadprofileListOpen={true}
      loadprofileType="consumption"
      variantId={variantId}
      {...props}
    />
  );

  return (
    <CustomForm
      buttonContainer={buttonContainer}
      CustomFormItemsComponent={CustomFormItemsComponent}
      formFields={selectedFormFields}
      key="loadprofile"
      patchUrl={patchUrl}
      submitButtonText="Weiter"
      onSubmit={onLoadProfileSelectionDone}
    />
  );
}

ConsumerWizard.propTypes = {
  siteId: PropTypes.number.isRequired,
  variantId: PropTypes.number.isRequired,
  product: PropTypes.string.isRequired,
  buttonContainer: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onUpdateModalTitle: PropTypes.func.isRequired
};

export { ConsumerWizard };
