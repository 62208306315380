import { Box, Paper, SimpleGrid, Title } from "@mantine/core";
import type { MeteringConceptYearlyAggregateValues } from "../../../../utils/aggregateMeteringConceptComparisonData";
import type { DecimalLabelProps } from "../../../BuildingBlocks/DecimalLabel/DecimalLabel";
import type { VariantMeteringConceptConfiguration } from "../VariantComparison";
import { AlertRow } from "./AlertRow";
import { TopRow } from "./TopRow";
import { ValueRow } from "./ValueRow";

export interface RowConfigurationEntry {
  rowLabel: string;
  variable: string;
  labelConfiguration: DecimalLabelProps;
}

const rowConfigurations = [
  {
    rowLabel: "Gesamterlöse / -kosten",
    variable: "totalCosts",
    labelConfiguration: {
      suffix: " €",
      decimalScale: 0,
      convert: (value: number) => (isNaN(value) ? value : -value),
      fontDangerIfNegative: true
    }
  },
  {
    rowLabel: "Vorteil zu Basiskonzept",
    variable: "savings",
    labelConfiguration: {
      suffix: " €",
      decimalScale: 0,
      fontDangerIfNegative: true
    }
  },
  {
    rowLabel: "Lieferungen innerhalb der Kundenanlage",
    variable: "localDelivery",
    labelConfiguration: {
      suffix: " kWh",
      decimalScale: 0
    }
  },
  {
    rowLabel: "Lokalversorgungsanteil",
    variable: "localShare",
    labelConfiguration: {
      suffix: " %",
      decimalScale: 1,
      convert: (value: number) => 100 * value
    }
  }
] as const satisfies Array<RowConfigurationEntry>;

export interface MeteringConceptYearlyAggregateWithSavings
  extends MeteringConceptYearlyAggregateValues {
  savings?: number;
}

interface VariantComparisonHeaderProps {
  formatForReport: boolean;
  isAnalyzeView: boolean;
  meteringConceptConfiguration: Record<
    number,
    VariantMeteringConceptConfiguration
  >;
  meteringConceptVariantIds: Array<number>;
  yearlyAggregates: Record<string, MeteringConceptYearlyAggregateValues>;
  onMoveLeft?: (variantId: number) => void;
  onMoveRight?: (variantId: number) => void;
}

function VariantComparisonHeader({
  formatForReport,
  isAnalyzeView,
  meteringConceptConfiguration,
  meteringConceptVariantIds,
  yearlyAggregates,
  onMoveLeft,
  onMoveRight
}: VariantComparisonHeaderProps) {
  const referenceValue = yearlyAggregates[meteringConceptVariantIds[0]];

  const yearlyAggregatesWithSavings: Record<
    string,
    MeteringConceptYearlyAggregateWithSavings
  > = Object.keys(yearlyAggregates).reduce((obj, key) => {
    if (referenceValue) {
      const referenceCosts = Number(referenceValue.totalCosts);
      const savings = referenceCosts - Number(yearlyAggregates[key].totalCosts);
      obj[key] = Object.assign(yearlyAggregates[key], { savings });
    } else {
      obj[key] = yearlyAggregates[key];
    }
    return obj;
  }, {});

  const title = isAnalyzeView
    ? "Ergebnisübersicht"
    : "Ergebnisübersicht Variantenvergleich";

  return (
    <Box
      className="VariantComparisonHeader"
      miw={320 * meteringConceptVariantIds.length}
    >
      <Paper mb="md" p={formatForReport ? 0 : undefined}>
        <Title variant="paper-header">{title}</Title>
        <SimpleGrid
          cols={meteringConceptVariantIds.length + 1}
          spacing={0}
          verticalSpacing={0}
        >
          <TopRow
            meteringConceptConfiguration={meteringConceptConfiguration}
            meteringConceptVariantIds={meteringConceptVariantIds}
            onMoveLeft={onMoveLeft}
            onMoveRight={onMoveRight}
          />
          {rowConfigurations.map((rowConfiguration) => (
            <ValueRow
              key={rowConfiguration.variable}
              meteringConceptVariantIds={meteringConceptVariantIds}
              rowConfiguration={rowConfiguration}
              yearlyAggregatesWithSavings={yearlyAggregatesWithSavings}
            />
          ))}
          <AlertRow
            meteringConceptConfiguration={meteringConceptConfiguration}
            meteringConceptVariantIds={meteringConceptVariantIds}
          />
        </SimpleGrid>
      </Paper>
    </Box>
  );
}

export { VariantComparisonHeader };
