import { useEffect } from "react";
import { useGhostNodeMutation } from "../../hooks/useGhostNodeMutation";
import type { GhostNode } from "../../utils/backend-types";
import { showToast } from "../../utils/toast";
import type { WizardModeProps } from "../VariantObjectWizard/VariantObjectWizard";
import { GhostNodeForm } from "./GhostNodeForm";
import "./GhostNodeWizard.scss";

type GhostNodeWizardProps = {
  buttonContainer?: HTMLElement;
  siteId: number;
  onClose: () => void;
  graphCoordinates: { x: number; y: number };
} & WizardModeProps<GhostNode>;

function GhostNodeWizard({
  buttonContainer,
  siteId,
  onClose,
  graphCoordinates,
  ...modeProps
}: GhostNodeWizardProps) {
  const { isSuccess: wasFormSubmittedSuccessfully, mutateAsync } =
    useGhostNodeMutation(modeProps.mode, siteId, graphCoordinates);

  useEffect(() => {
    if (wasFormSubmittedSuccessfully) {
      onClose();
      showToast(
        "success",
        modeProps.mode === "create"
          ? "Der Verteilungspunkt wurde erstellt."
          : "Die Änderungen wurden gespeichert."
      );
    }
  }, [wasFormSubmittedSuccessfully, modeProps.mode, onClose]);

  return (
    <div className="GhostNodeWizard">
      <GhostNodeForm
        buttonContainer={buttonContainer}
        onCancel={onClose}
        onSubmit={mutateAsync}
        {...modeProps}
      />
    </div>
  );
}

export { GhostNodeWizardProps, GhostNodeWizard };
