import { Stack } from "@mantine/core";
import { IconCaretLeftFilled } from "@tabler/icons-react";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import type { IconName } from "../../BuildingBlocks/Icon/types";

interface MenuItemContentProps {
  icon: IconName;
  label: string;
}

function MenuItemContent({ icon, label }: MenuItemContentProps) {
  return (
    <div className="caret-container">
      <Stack align="center" gap={0} ta={"center"}>
        <Icon name={icon} style={{ fontSize: "2.2rem" }} />
        {label}
      </Stack>
      <div className="caret">
        <IconCaretLeftFilled />
      </div>
    </div>
  );
}

export { MenuItemContent };
