import classnames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { generatePath, Link, useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useRouteVariantId } from "../../hooks/useRouteVariantId";
import { ROUTES } from "../../routes";
import urls from "../../urls";
import { AssessmentPage } from "../AssessmentPage/AssessmentPage";
import { EnergyFlowsPage } from "../EnergyFlowsPage/EnergyFlowsPage";
import { IndividualGridFeesView } from "../IndividualGridFeesView/IndividualGridFeesView";
import { LoggingView } from "../LoggingView/LoggingView";
import { MeteringConceptPage } from "../MeteringConceptPage/MeteringConceptPage";
import withVariantSelection from "../PageWithVariantSelection/PageWithVariantSelection";
import { RegulatoryDutiesPage } from "../RegulatoryDutiesPage/RegulatoryDutiesPage";
import "./AnalyzeView.scss";

const TABS = {
  TAB_INDIVIDUAL_GRID_FEES: "individuelle-netzentgelte",
  TAB_STORAGE_ASSESSMENT: "batteriespeicher",
  TAB_FINANCIAL_ASSESSMENT: "finanzielle-bewertung",
  TAB_REGULATORY_DUTIES: "regulatorische-pflichten",
  TAB_ENERGY_FLOWS: "energieflüsse",
  TAB_METERING_CONCEPT: "bilanzierungsvorschriften",
  TAB_LOGGING_VIEW: "protokoll"
};

export function pathToLoggingTab(projectId, variantId) {
  return generatePath(ROUTES.analyseSubPage, {
    variantId: variantId,
    projectId: projectId,
    analysisPage: TABS.TAB_LOGGING_VIEW
  });
}

export const DEFAULT_SUBPAGE = TABS.TAB_FINANCIAL_ASSESSMENT;

function AnalyzeView({ displayNames, selectedVariantIds, variants }) {
  const { analysisPage } = useParams();
  const variantId = useRouteVariantId();
  const [cachedTabs, setCachedTabs] = useState([analysisPage]);

  const isTabCached = useCallback(
    (tab) => {
      return !!cachedTabs.includes(tab);
    },
    [cachedTabs]
  );

  useEffect(() => {
    if (!isTabCached(analysisPage)) {
      const newCachedTabs = [...cachedTabs];
      newCachedTabs.push(analysisPage);
      setCachedTabs(newCachedTabs);
    }
  }, [analysisPage, cachedTabs, isTabCached]);

  const activeTab = analysisPage;

  return (
    <div className="AnalyzeView">
      <Nav className="tabs" pills>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === TABS.TAB_FINANCIAL_ASSESSMENT
            })}
            tag={Link}
            to={`../${TABS.TAB_FINANCIAL_ASSESSMENT}`}
          >
            Finanzielle Bewertung
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === TABS.TAB_REGULATORY_DUTIES
            })}
            tag={Link}
            to={`../${TABS.TAB_REGULATORY_DUTIES}`}
          >
            Regulatorische Pflichten
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === TABS.TAB_INDIVIDUAL_GRID_FEES
            })}
            tag={Link}
            to={`../${TABS.TAB_INDIVIDUAL_GRID_FEES}`}
          >
            Individuelle Netzentgelte
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === TABS.TAB_ENERGY_FLOWS
            })}
            tag={Link}
            to={`../${TABS.TAB_ENERGY_FLOWS}`}
          >
            Energieflüsse
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === TABS.TAB_METERING_CONCEPT
            })}
            tag={Link}
            to={`../${TABS.TAB_METERING_CONCEPT}`}
          >
            Bilanzierungsvorschriften
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === TABS.TAB_LOGGING_VIEW
            })}
            tag={Link}
            to={`../${TABS.TAB_LOGGING_VIEW}`}
          >
            Protokoll
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane
          className="IndividualGridFees"
          tabId={TABS.TAB_INDIVIDUAL_GRID_FEES}
        >
          {activeTab === TABS.TAB_INDIVIDUAL_GRID_FEES ||
          isTabCached(TABS.TAB_INDIVIDUAL_GRID_FEES) ? (
            <IndividualGridFeesView variantId={variantId} />
          ) : null}
        </TabPane>
        <TabPane tabId={TABS.TAB_FINANCIAL_ASSESSMENT}>
          {activeTab === TABS.TAB_FINANCIAL_ASSESSMENT ||
          isTabCached(TABS.TAB_FINANCIAL_ASSESSMENT) ? (
            <AssessmentPage
              displayNames={displayNames}
              isAnalyzeView
              selectedVariantIds={selectedVariantIds}
              variants={variants}
            />
          ) : null}
        </TabPane>
        <TabPane tabId={TABS.TAB_REGULATORY_DUTIES}>
          {activeTab === TABS.TAB_REGULATORY_DUTIES ||
          isTabCached(TABS.TAB_REGULATORY_DUTIES) ? (
            <RegulatoryDutiesPage
              displayNames={displayNames}
              selectedVariantIds={selectedVariantIds}
            />
          ) : null}
        </TabPane>
        <TabPane tabId={TABS.TAB_ENERGY_FLOWS}>
          {activeTab === TABS.TAB_ENERGY_FLOWS ||
          isTabCached(TABS.TAB_ENERGY_FLOWS) ? (
            <EnergyFlowsPage selectedVariantIds={selectedVariantIds} />
          ) : null}
        </TabPane>
        <TabPane tabId={TABS.TAB_METERING_CONCEPT}>
          {activeTab === TABS.TAB_METERING_CONCEPT ||
          isTabCached(TABS.TAB_METERING_CONCEPT) ? (
            <MeteringConceptPage variantId={variantId} />
          ) : null}
        </TabPane>
        <TabPane tabId={TABS.TAB_LOGGING_VIEW}>
          {activeTab === TABS.TAB_LOGGING_VIEW ||
          isTabCached(TABS.TAB_LOGGING_VIEW) ? (
            <LoggingView variantId={variantId} />
          ) : null}
        </TabPane>
      </TabContent>
    </div>
  );
}

const AnalyzeViewWithVariantSelection = withVariantSelection(AnalyzeView);

const AnalyzeViewWithVariantSelectionWithDataUrls = ({
  projectId,
  ...otherProps
}) => {
  const dataUrls = {
    project: urls.api.projectsDetail(projectId)
  };

  return (
    <AnalyzeViewWithVariantSelection dataUrls={dataUrls} {...otherProps} />
  );
};

export { AnalyzeViewWithVariantSelectionWithDataUrls as AnalyzeView };
